<template>
  <material-card
    id="dataMap"
    v-resize="onResize"
    style="width:100%"
    :title="$t('charts.regional_lab_results')"
    icon="fal fa-map-location"
    :text="$t('charts.last_x_units', { value: '30', units: $tc('day', 2) })"
    color="purple darken-1"
    class="data-map mt-10"
    :loading="loading"
  >
    <template #contents>
      <v-card-title class="text-caption py-0">
        <v-btn
          v-if="scope == 'usa'"
          fab
          outlined
          color="primary lighten-4"
          small
          class="mt-4"
          elevation="2"
          @click="labels = !labels"
        >
          <v-icon small>
            {{ labels ? 'fa-kit fa-light-tags-slash' : 'fal fa-tags' }}
          </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          fab
          outlined
          color="primary lighten-4"
          small
          class="mt-4"
          elevation="2"
          @click="scope = scope == 'usa' ? 'world' : 'usa'"
        >
          <v-icon small>
            {{ scope == 'usa' ? 'fal fa-earth-americas' : 'fal fa-flag-usa' }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <vue-datamaps
        :key="`${scope}_map`"
        :style="cssVars"
        :scope="scope"
        :data="mapData"
        :fills="fills"
        :geography-config="geographyConfig"
        popup-template
        :labels="labels"
        class="mt-n16 mb-n6"
        @custom:popup="popupTemplate"
      >
        <v-card
          v-if="popupData"
          slot="hoverinfo"
          class="hoverinfo py-0 px-2"
          dense
        >
          <v-list-item-title
            v-if="popupData.geography && popupData.geography.properties"
            class="font-weight-medium"
          >
            {{ popupData.geography.properties.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="popupData.datum">
            Lab Results: {{ popupData.datum.lab_results | numeral }}
          </v-list-item-subtitle>
        </v-card>
      </vue-datamaps>

      <v-card-actions>
        <table
          v-if="rawMapData.length"
          class="legend"
        >
          <tr>
            <td
              v-for="color, index in Object.values(fills).slice(0, 10)"
              :key="index"
              xstyle="`background-color:${color}`"
              xclass="white--text text-caption"
              zwidth="10%"
            >
              <v-btn
                class="px-0 text-caption"
                :color="!active_label || active_label == index + 1 ? color : fills.defaultFill"
                xstyle="width:100%"
                label
                x-small
                dark
                block
                elevation="1"
                tile
                @click="active_label != index + 1 ? active_label = index + 1 : active_label = null"
              >
                {{ (index ? tier * index : 1) | number('0.0a') }} - {{ tier * (index + 1) | number('0.0a') }}
              </v-btn>
            </td>
          </tr>
        </table>
      </v-card-actions>
    </template>
  </material-card>
</template>
<script>
import { VueDatamaps } from 'vue-datamaps'

export default {
  components: {
    VueDatamaps,
  },
  data: () => ({
    scope: 'usa',
    rawMapData: [],
    loading: false,
    fills: {
      10: '#1C2678',
      9: '#352493',
      8: '#642DAE',
      7: '#9C36C8',
      6: '#DC40E1',
      5: '#D253E5',
      4: '#CB65E9',
      3: '#C778EC',
      2: '#C68CF0',
      1: '#C89FF3',
      defaultFill: '#CCC',
    },
    geographyConfig: {
    //   xhighlightBorderColor: '#bada55',
    //   highlightBorderWidth: 3,
    //   borderWidth: 1,
    },
    popupData: null,
    labels: false,
    active_label: null,
    width: 0,
  }),
  computed: {
    mapData () {
      var fillKey
      var states = {}
      let mapData = [ ...this.rawMapData ]
      mapData.forEach(x => {
        fillKey = Math.ceil(x.lab_results / this.tier)
        if (this.active_label != null && this.active_label != fillKey) {
          return
        }
        states[x.key] = x
        states[x.key].fillKey = fillKey
      })
      return states
    },
    tier () {
      var max = Math.max(...this.rawMapData.map(x => x.lab_results))
      // var min = Math.min(...this.rawMapData.map(x => x.lab_results))
      return (Math.ceil(max/100) * 100) / 10
    },
    cssVars() {
      return {
        '--map-height': (this.width * 0.55) + "px",
      }
    },
  },
  watch: {
    scope () {
      if (this.scope == 'world') {
        this.labels = false
      }
      this.fetchData(true)
    },
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData (no_cache = false) {
      this.loading = true,
      this.axios.get('charts/locations', {
        params: {
          no_cache: no_cache,
          scope: this.scope,
        },
      })
        .then((res) => {
          this.$set(this, 'rawMapData', res.data)
        })
        .catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => {
          this.loading = false
          window.dispatchEvent(new Event('resize'));
        })
    },
    popupTemplate ({ geography, datum }) {
      this.popupData = { geography, datum }
    },
    onResize () {
      this.width = document.getElementById('dataMap')?.offsetWidth ?? this.width
    },
  },
}
</script>
<style lang="scss">
.datamap {
  width: 100%;
  height: var(--map-height);

  path {
    stroke: rgba(255, 255, 255, 0.65) !important;
  }
}
.data-map .legend {
  width: 100%;
  height: 20px;
  text-align: center;
}
</style>
